import React, { useEffect, useState } from 'react';
import 'antd/dist/antd.css';
import './tabSelect.less';
import api from "../../../API/index";
import { Link } from 'react-router-dom';
import { Select } from 'antd';


const { Option } = Select;
export default function TabSelects(props) {
  const { setSearchInputval, setTabPosition, getTagList, setGetTagList } = props
  const [tagList, setTagList] = useState([])//data数据
  const [queryInfo, setQueryInfo] = useState({})
  const [name, setName] = useState('')
  const [orderBy, setorderBy] = useState('')
  const [optioninfo, setOptioninfo] = useState([])
  useEffect(() => getTagInfo(), [])
  const getTag = (params) => {
    api.home.get_tags(params).then(res => {
      const { data, status } = res
      if (status === 200) {
        if (data && Reflect.has(data, 'data') && data.data.length) {
          setGetTagList(data.data)
        }
      }
    });
  }
  useEffect(() => {
    getTag()
  }, [])

  const getTagInfo = () => {
    api.home.get_tag_filter().then(res => {
      const { data, status } = res
      if (status === 200) {
        setTagList(data.data)
        setName(data.data.title.name)
        setorderBy(data.data.title.value)
        setOptioninfo(data.data.value)
      }
    });
  }
  const changeTab = (value, key) => {//Select回调
    setSearchInputval('')
    setTabPosition('')
    let obj = { [key]: value }
    setQueryInfo(Object(queryInfo, obj))
    let params = { order_by: value }
    getTag(params);
  }

  return (
    <>
      <div className='tagName'>
        {
          <div >
            <div className='tagState'>{name}</div>
            <Select defaultValue='按字母从A到Z' style={{ width: 200 }} onChange={(value) => changeTab(value, value.value)}>
              {
                optioninfo.map((item, els) => {
                  const { value, name = '' } = item
                  return <Option key={els} value={value}>{name}</Option>
                })
              }
            </Select>
          </div>
        }
      </div >
      <div className='tableList'>
        {
          getTagList.length && getTagList.map((ele, index) => {
            const { game_num = {}, tag_name = {}, id = {} } = ele

            return (
              <div key={index} className='table'>
                <Link to={`/Labeldetails?id=${[id]}&tag_name=${[tag_name]}&game_num=${[game_num]}`}>
                  {tag_name}
                  ({game_num})
                </Link>
              </div>
            )
          })
        }
      </div>
    </>
  )
}



