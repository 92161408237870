import React, { useEffect, useState } from "react";
import { Tabs } from 'antd';
import "./Home.less";
import Heads from "../public/head/head";
import Cards from "../public/card/card";
import TabSelects from "../public/tabSelects/tabSelects";
import api from "../../API/index"

const { TabPane } = Tabs;
export default function Home() {
  const [dataList, setDataList] = useState([])
  const [queryInfo, setQueryInfo] = useState({})
  const [searchInputval, setSearchInputval] = useState('')// 搜索input值
  const [tabPosition, setTabPosition] = useState('')// input后面标签值
  const [getTagList, setGetTagList] = useState([])//data数据
  const from = window.sessionStorage.getItem("from") || '1'
  const getTag = () => {
    api.home.get_tags().then(res => {
      const { data, status } = res
      if (status === 200) {
        if (data && Reflect.has(data, 'data') && data.data.length) {
          setGetTagList(data.data)
        }
      }
    });
  }
  const getInfo = async (params) => {
    const res = await api.home.get_games(params)
    const { data, status } = res
    if (status === 200 && data && Reflect.has(data, 'data') && data.data.length) {
      setDataList(data.data)
    } else {
      setDataList([])
    }
  }
  useEffect(() => {
    getTag()
  }, [])
  const get_gam = () => {
    api.home.get_games().then(res => {
      const { data, } = res
      const { code, id } = data
      if (code === 200) {
        if (data && Reflect.has(data, 'data') && data.data.length) {
          setDataList(data.data)
        }
      }
    });
  }



  useEffect(() => {
    const keyword = window.sessionStorage.getItem('keyWord')
    if (keyword) {
      let params = JSON.parse(keyword)
      getInfo(params)
      setQueryInfo(params)
    }
    else {
      get_gam()
    }
  }, [])
  const changeTab = (e) => {
    window.sessionStorage.setItem("from", e)
  }
  return (
    <div className="carfountain_home" >
      <Tabs
        tabBarExtraContent="Carfountain游戏管理后台"
        type="line"
        defaultActiveKey={from}
        onChange={changeTab}>
        <TabPane tab="后台首页" key="1">
          <Heads
            {...{
              searchInputval, setSearchInputval, tabPosition, searchInputval,
              setDataList, queryInfo, setQueryInfo, setTabPosition, dataList, setDataList
            }}

          />
          <div className="cardList">
            {
              dataList.length && dataList.map((item, index) => {
                return (
                  <Cards key={index} index={index} item={item} dataList={dataList} />
                )
              })
            }
          </div>
        </TabPane>
        <TabPane tab="标签管理" key="2">
          <TabSelects    {...{
            setTabPosition, setSearchInputval, queryInfo, setQueryInfo, getTagList, setGetTagList
          }} />
        </TabPane>
      </Tabs>
    </div >
  )
}