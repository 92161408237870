import React, { useState, useEffect, useRef } from 'react';
import 'antd/dist/antd.css';
import './headTab.less';
import api from "../../../API/index"
import { Radio, Space, Input, AutoComplete } from 'antd';


const HeadTab = (props) => {
  const { tabPosition, setQueryInfo, setTabPosition, searchInputval, setSearchInputval, setDataList, dataList } = props
  const tagPosition = {
    'game_name': '游戏',
    'tag_name': '标签'
  }
  const formRef = useRef(null);
  const changeTabPosition = e => {
    setTabPosition(e.target.value)
    setQueryInfo({})
  };
  const onSearch = value => {
    setQueryInfo({})
    setSearchInputval(value)
    api.home.get_games({ search_condiction: tabPosition, search_key: value }).then(res => {
      const { data, } = res
      const { code, id } = data
      if (code === 200) {
        if (data && Reflect.has(data, 'data') && data.data.length) {
          setDataList(data.data)
        }
      }
    });
  }
  const onChange = (e) => {
    setSearchInputval(e.target.value)
  }
  useEffect(() => {
    if (!searchInputval) {
      formRef.current.input.value = ''
    }
  }, [searchInputval])
  return (
    <>
      <div className='headSeach'>
        <AutoComplete
          dropdownClassName="certain-category-search-dropdown"
          dropdownMatchSelectWidth={500}
          style={{
            width: 250,
          }}
          options={[]}
        >
          <Input.Search ref={formRef} value={searchInputval} size="large" placeholder="搜索游戏或标签名称" onChange={onChange} onSearch={onSearch} />
        </AutoComplete>
      </div>
      <div className='headSpace'>
        <Space style={{ marginBottom: 24 }}>
          <Radio.Group value={tabPosition} onChange={changeTabPosition}>
            <Radio.Button value="game_name" >{tagPosition.game_name}</Radio.Button>
            <Radio.Button value="tag_name" >{tagPosition.tag_name}</Radio.Button>
          </Radio.Group>
        </Space>
      </div>
    </>
  );
}

export default HeadTab;