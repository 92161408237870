import "./Labeldetails.less";
import { Tabs, message, PageHeader } from 'antd';
import Modals from "../public/modal/modal";
import Cards from "../public/card/card";
import Deteltag from "../public/detel/detel";
import React, { useState, useEffect } from "react";
import api from "../../API/index"

export default function Labeldetails() {
  const routes = [
    {
      path: '/home',
      breadcrumbName: 'Home',
    },
    {
      path: 'first',
      breadcrumbName: 'Labeldetails',
    },

  ];
  const [dataList, setDataList] = useState([])
  const [labTagName, setLabTagName] = useState('')
  const [visible, setVisible] = useState(false);
  function getUrlParams(url) {
    const _url = url || window.location.href;
    const _urlParams = _url.match(/([?&])(.+?=[^&]+)/igm);
    return _urlParams ? _urlParams.reduce((a, b) => {
      const value = b.slice(1).split('=');
      a[value[0]] = value[1]
      return a;
    }, {}) : {};
  }
  const { tag_name = '', game_num = '', id = '' } = getUrlParams() || {}
  const get_gam = () => {
    api.home.get_games({ game_category: id }, id).then(res => {
      const { data, } = res
      const { code, id } = data
      if (code === 200) {
        if (data && Reflect.has(data, 'data') && data.data.length) {
          setDataList(data.data)
        }
      }
    });
  }
  const Get_tag_name = () => {
    api.home.get_tags_name({}, id).then(res => {
      const { data } = res
      setLabTagName(data.data.tag_name)
    })
  }
  const Up_tag = (value) => {
    api.home.up_tag_info({ tag_name: value }, id).then(res => {
      const { status, data = {} } = res
      const { code } = data
      if (status === 200 && code === 200) {
        message.success('修改成功')
        hideModal();
        Get_tag_name()
      } else {
        message.error('修改失败')
      }

    })
  }
  const showModal = () => {
    setVisible(true);
  };

  const hideModal = () => {
    setVisible(false);
  };
  const goBackPage = () => {
    window.history.back()
  }

  const detel_tagName = () => {
    api.home.delete_tag({}, id).then(res => {
      const { status, data = {} } = res
      const { code } = data
      if (status === 200 && code === 200) {
        message.success('删除成功,将返回上一页')
        hideModal();
        setTimeout(() => {
          goBackPage();
        }, 2000);
      } else {
        message.error('删除失败')
      }

    })
  }

  useEffect(() => {

    Get_tag_name();
    get_gam();
  }, [])

  return (
    <div className="carfountain_labeldetails" >
      <Tabs
        tabBarExtraContent="Carfountain标签详情页"
        type="line"
        defaultActiveKey="3">
      </Tabs>
      <PageHeader
        className="site-page-header"
        breadcrumb={{ routes }}
      />
      <div className="label-title">
        {labTagName}({game_num})
        <span className="label-button"  > <Modals Up_tag={Up_tag} visible={visible} showModal={showModal} hideModal={hideModal} />
          <span className="label-detel">  <Deteltag detel_tagName={detel_tagName} visible={visible} showModal={showModal} hideModal={hideModal} /></span></span>
      </div>
      <div className="cardList">
        {
          dataList.length && dataList.map((item, index) => {
            return (
              <Cards key={index} index={index} item={item} dataList={dataList} />
            )
          })
        }
      </div>
    </div >
  )
}