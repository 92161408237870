import React from "react";
import "antd/dist/antd.css";
import "./modal.less";
import { Modal, Button, Input } from "antd";
import { useState } from "react";


export default function Modals(props) {
  const { Up_tag, visible, showModal, hideModal } = props

  const [tagVaule, setTagVaule] = useState('')


  const getVaule = (e) => {
    setTagVaule(e.target.value)
  }
  const onOk = () => {
    Up_tag(tagVaule)
  }


  return (
    <>
      <Button type="primary" onClick={showModal}>
        修改标签名称
      </Button>
      <Modal
        title="修改标签名称"
        visible={visible}
        onOk={onOk}
        onCancel={hideModal}
        destroyOnClose
        okText="确认"
        cancelText="取消"
      >
        <Input placeholder="请输入新标签" onChange={(e) => getVaule(e)} />
      </Modal>
    </>
  );

}




