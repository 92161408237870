import React from "react";
import { HashRouter, Routes, Route, Navigate } from 'react-router-dom';
import { AliveScope } from 'react-activation'
import 'antd/dist/antd.min.css';
import Home from './Home/Home';
import Details from "./Details/Details";
import Labeldetails from "./Labeldetails/Labeldetails";

//可以在这里配路由

export default function App() {
  return (
    <HashRouter>
      <Routes>
        {/* <AliveScope> */}
        <Route path='/home' element={<Home />} />
        {/* </AliveScope> */}
        <Route path='/details' element={<Details />} />
        <Route path='/labeldetails' element={<Labeldetails />} />
        <Route path="/*" element={<Navigate to="/home" />} />

      </Routes>
    </HashRouter>
  )
}

