import React, { Link } from 'react';
import { Button } from 'antd';
import './play.less';


export default function Play(props) {
  const { cardDet } = props
  const { game_icon_url = '', play_url = '' } = cardDet

  return (
    <>
      <img className='play-main' src={game_icon_url}>

      </img>
      <div className='play-button'>
        <a href={play_url} target="_blank"><Button type="primary">play</Button> </a>
      </div>
    </>
  )
}
