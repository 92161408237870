import "./Details.less"
import { Tabs, PageHeader } from 'antd';
import Play from "../public/play/play";
import SetUp from "../public/setUp/setUp";
import Article from "../public/article/article";
import api from "../../API/index";
import React, { useEffect, useState } from "react";

export default function Details() {
  const routes = [
    {
      path: '/home',
      breadcrumbName: 'Home',
    },
    {
      path: 'first',
      breadcrumbName: 'Details',
    },

  ];
  const [cardDet, setCardDet] = useState({})
  const [visible, setVisible] = useState(false);
  function getUrlParams(url) {
    const _url = url || window.location.href;
    const _urlParams = _url.match(/([?&])(.+?=[^&]+)/igm);
    return _urlParams ? _urlParams.reduce((a, b) => {
      const value = b.slice(1).split('=');
      a[value[0]] = value[1]
      return a;
    }, {}) : {};
  }
  const { id = '' } = getUrlParams() || {}
  const Getdetail = () => {
    api.home.get_games_detail({}, id).then(res => {
      const { data, status } = res
      if (status === 200) {
        setCardDet(data.data)
      }
    });
  }
  const showModal = () => {
    setVisible(true);
  };

  const hideModal = () => {
    setVisible(false);
  };
  useEffect(() => {
    Getdetail()
  }, [])
  return (
    <div className="carfountain_details" >
      <Tabs
        tabBarExtraContent="Carfountain游戏详情页"
        type="line"
        defaultActiveKey="2">
      </Tabs>
      <PageHeader
        className="site-page-header"
        breadcrumb={{ routes }}
      />
      <div className="details-main">
        <Play cardDet={cardDet} />
        <div className="details-right">
          <SetUp cardDet={cardDet} id={id} visible={visible} showModal={showModal} hideModal={hideModal} setVisible={setVisible} />
          <Article cardDet={cardDet} />
        </div>
      </div>
    </div >
  )
}






