import axios from "axios";
import qs from "qs";

function get_filter(params) {
    return axios.get("/api/games/filter_condition", { params })
}

function get_tag_filter(params) {
    return axios.get("api/game_tags/filter_condition", { params })
}

function get_games(params) {
    return axios.get('api/games', { params })
}

function get_games_detail(params, game_id) {
    return axios.get(`api/games/${game_id}`, { params })
}

function up_games_info(params, game_id) {
    return axios.put(`api/games/${game_id}`, params)
}

function get_tags(params = {}) {
    return axios.get("api/game_tags", { params })
}

function get_tags_name(params, tag_id) {
    return axios.get(`/api/game_tags/${tag_id}`, { params })
}

function create_tag(params) {
    return axios.post("api/game_tags", params)
}

function up_tag_info(params, game_id) {
    return axios.put(`api/game_tags/${game_id}`, params)
}

function delete_tag(params, tag_id) {
    return axios.delete(`api/game_tags/${tag_id
    }`, params)
}



export default {
    get_filter,
    get_games,
    get_games_detail,
    up_games_info,
    get_tag_filter,
    get_tags,
    create_tag,
    get_tags_name,
    up_tag_info,
    delete_tag
}