import React from 'react';
import './article.less';
import {
  Avatar,
  Row,
  Col
} from 'antd';
import 'antd/dist/antd.css';



export default function Article(props) {
  const {
    cardDet
  } = props;
  const {
    game_icon_url = '', game_name = '', desc_es = '', desc_fr = '', origin_game_id = '', lastUpdate = '', update_time = '', desc_it = '', thumbnailUrl = '', creation = '', game_category = '', create_time = '', description = '', id = '', play_url = '', approval_date = '', how_to_play = '', supported_device = ''
  } = cardDet

  return (
    <>
      <div className='article-main'>
        <h3 > 信息 </h3>
        <div className='acticle-list'>
          <Row >
            < Col span={4} > Logo: </Col>
            < Col span={16} offset={2} >
              < Avatar shape="square" size={64} src={game_icon_url} />
            </Col >
          </Row >
          <Row>
            < Col span={4} > Title: </Col>
            < Col span={16} offset={2} >{game_name} </Col >
          </Row >
          <Row>
            < Col span={4} > ID: </Col>
            < Col span={16} offset={2} > {id} </Col >
          </Row >
          <Row>
            < Col span={4} > GamePixID: </Col>
            < Col span={16} offset={2} >{origin_game_id} </Col >
          </Row >
          <Row >
            < Col span={4} > Description: </Col>
            < Col span={16} offset={2} > {description} </Col >
          </Row >

          <Row>
            < Col span={4} > ThumbnailURL: </Col>
            < Col span={16} offset={2} > {thumbnailUrl} </Col >
          </Row >
          <Row>
            < Col span={4} > URL: </Col>
            < Col span={16} offset={2} >{play_url} </Col >
          </Row >
          <Row>
            < Col span={4} > how_to_play: </Col>
            < Col span={16} offset={2} > {how_to_play} </Col >
          </Row >
          <Row>
            < Col span={4} > supported_device: </Col>
            < Col span={16} offset={2} > {supported_device} </Col >
          </Row >
          <Row>
            < Col span={4} > approval_date: </Col>
            < Col span={16} offset={2} > {approval_date}</Col >
          </Row >
          <Row>
            < Col span={4} > createTime:</Col>
            < Col span={16} offset={2} > {create_time} </Col >
          </Row >
          <Row>
            < Col span={4} > creation: </Col>
            < Col span={16} offset={2} > {creation} </Col >
          </Row >
          <Row>
            < Col span={4} > lastUpdate: </Col>
            < Col span={16} offset={2} >{lastUpdate} </Col >
          </Row >
          <Row>
            < Col span={4} > updateTime: </Col>
            < Col span={16} offset={2} > {update_time} </Col >
          </Row >
          <Row>
            < Col span={4} > desc_it: </Col>
            < Col span={16} offset={2} > {desc_it} </Col >
          </Row >
          <Row>
            < Col span={4} > desc_es: </Col>
            < Col span={16} offset={2} >{desc_es} </Col >
          </Row >
          <Row>
            < Col span={4} > desc_fr: </Col>
            < Col span={16} offset={2} > {desc_fr} </Col >
          </Row >
        </div>
      </div>
    </>
  );
}