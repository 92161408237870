import React, { useState, useEffect } from 'react';
import { Select, Radio, Space, Divider, Input, Modal, message, Button } from 'antd';
import './setUp.less';
import 'antd/dist/antd.css';
import api from "../../../API/index"

const { Option } = Select;

export default function SetUp(props) {

  const { cardDet, visible, showModal, hideModal, setVisible } = props
  const [tagId, setTagID] = useState([])
  const [tagName, setTagName] = useState("");
  const [getTagList, setGetTagList] = useState([])
  const [quality, setQuality] = useState('')
  const [update, setUpdate] = useState(false)
  const [option, setOption] = useState([])
  let { game_category = [], game_quality_explanation = [], game_quality = '', } = cardDet

  useEffect(() => getTag(), [])

  const getUrlParams = (url) => {
    const _url = url || window.location.href;
    const _urlParams = _url.match(/([?&])(.+?=[^&]+)/igm);
    return _urlParams ? _urlParams.reduce((a, b) => {
      const value = b.slice(1).split('=');
      a[value[0]] = value[1]
      return a;
    }, {}) : {};
  }

  const getTag = () => {
    api.home.get_tags().then(res => {
      const { data, status } = res
      if (status === 200) {
        if (data && Reflect.has(data, 'data') && data.data.length) {
          setGetTagList(data.data)
        }
      }
    });
  }

  const createTag = () => {
    api.home.create_tag({ tag_name: tagName }).then(res => {
      const { data } = res
      const { code } = data
      if (code === 200) {
        message.success('添加成功')
        setTagName("")
        getTag()
        if (tagId.length) {
          game_category.length = 0
          option.push({ name: tagName, value: data.data.tag_id })
          option.forEach(item => {
            game_category.push(item)
          })
        } else {
          game_category.push({ name: tagName, value: data.data.tag_id })
        }
        const value = game_category.map(item => item.value)
        setTagID(value)
        updateTag(value)
        setUpdate(true)
        setTimeout(() => {
          setUpdate(false)
        }, 500);
      } else {
        message.error('标签有重名，添加失败')
      }
    });
  }
  const addTag = (e) => {
    e.preventDefault();
    tagName && createTag()
    setVisible(false);
  };
  const onChange = (value, option) => {
    value = value.filter(num => Number.isInteger(num))
    setOption(option.map(item => {
      return {
        name: item.label,
        value: item.value
      }
    }))
    setTagID(value)
  }
  const handleSizeChange = (e) => {
    setQuality(e.target.value)
  }
  const preservation = () => {
    const { id = '' } = getUrlParams() || {}
    if (!tagId.length) {
      const morenId = game_category.map(item => item.value)
      api.home.up_games_info({
        tag_id_list: morenId,
        game_quality: quality
      }, id).then(res => {
      });
    } else {
      api.home.up_games_info({
        tag_id_list: tagId,
        game_quality: quality
      }, id).then(res => {
      });
    }

    debugger
  }


  const updateTag = (value) => {
    const { id = '' } = getUrlParams() || {}
    api.home.up_games_info({
      tag_id_list: value,
      game_quality: quality
    }, id).then(res => {
    });
  }
  const getVaule = (e) => {
    setTagName(e.target.value);
  }

  return (
    <div key={'setUP'}>
      <Button onClick={preservation}>保存</Button>
      <h3>设置</h3>
      <div className='set-title'>
        <span className='set-span'>标签 :</span>
        {
          props.cardDet.game_category && !update &&
          <Select
            mode="multiple"
            placeholder="Please select"
            autoFocus
            defaultValue={
              game_category.map(item => {
                return { "value": item.value, "label": item.name }
              })}
            style={{ width: "100%" }}
            optionFilterProp="children"
            onChange={onChange}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            filterSort={(optionA, optionB) =>
              optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
            }
            dropdownRender={(menu) => (
              <>
                {menu}
                <Divider style={{ margin: "8px 0" }} />
                {/* <Space align="center" style={{ padding: "0 8px 4px" }}>
                  <Input
                    placeholder="Please enter tag"
                    value={tagName}
                    onChange={onNameChange}
                  />
                  <Typography.Link onClick={addTag} style={{ whiteSpace: "nowrap" }}>
                    <PlusOutlined /> Add tag
                  </Typography.Link>
                </Space> */}
              </>
            )}
          >
            {getTagList.map((item) => (
              <Option key={item.id} value={item.id} label={item.tag_name}>{item.tag_name}</Option>
            ))}
          </Select>
        }

      </div>
      <Button onClick={showModal}>+添加标签</Button>
      <Modal
        title="为游戏添加标签"
        visible={visible}
        onOk={addTag}
        onCancel={hideModal}
        destroyOnClose
        okText="确认"
        cancelText="取消"
      >
        <Input placeholder="请输入新标签" onChange={(e) => getVaule(e)} />
      </Modal>
      <div className='set-title-two'>
        <span className='set-span'>质量 :</span>
        {
          game_quality &&
          <Space style={{ marginBottom: 24 }}>
            <Radio.Group onChange={handleSizeChange} defaultValue={game_quality}>
              {
                game_quality_explanation.map((ele, index) => {
                  const { name = '', value = '' } = ele
                  return (
                    <Radio.Button key={value} value={value}>{name}</Radio.Button>
                  )
                })
              }
            </Radio.Group>
          </Space>
        }

      </div>
    </div>
  )
}


