import React, { useState, useEffect } from "react";
import "antd/dist/antd.css";
import "./detel.less";
import { Modal, Button, } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";




export default function Deteltag(props) {
  const { detel_tagName, vivisible, showModal, hideModal } = props
  const onOk = () => {
    detel_tagName()
  }
  const confirm = () => {
    Modal.confirm({
      title: "确定删除标签吗",
      icon: <ExclamationCircleOutlined />,
      content: "删除标签不可撤销，并且标签下所有游戏将会去掉此标签",
      okText: "确认",
      cancelText: "取消",
      onOk: onOk
    });
  }
  return (
    <span className="detelButton">
      <Button type="primary" onClick={confirm}>删除标签</Button>
    </span>
  );


}



