import React, { useEffect, useState, } from 'react';
import 'antd/dist/antd.css';
import './select.less';
import api from "../../../API/index";
import { Select } from 'antd';


const { Option } = Select;
function Selects(props) {
  const { setDataList, queryInfo, setQueryInfo, setSearchInputval, setTabPosition } = props
  const [selectList, setSelectList] = useState([])
  useEffect(() => Get_fin(), [])
  const getInfo = async (params) => {
    const res = await api.home.get_games(params)
    const { data, status } = res
    if (status === 200 && data && Reflect.has(data, 'data') && data.data.length) {
      setDataList(data.data)
    } else {
      setDataList([])
    }
  }

  const Get_fin = () => {
    api.home.get_filter().then(res => {
      const { data, status } = res
      if (status === 200) {
        if (data && Reflect.has(data, 'data') && data.data.length) {
          setSelectList(data.data)
        }
      }
    });
  }


  const changeTab = (value, key) => {//Select回调
    setSearchInputval('')
    setTabPosition('')
    let obj = { [key]: value }
    setQueryInfo(Object.assign(queryInfo, obj))
    let params = {}
    Object.keys(queryInfo).map(ele => {
      if (queryInfo[ele] !== null) {
        params[ele] = queryInfo[ele]
      }
    })
    getInfo(params);
    window.sessionStorage.setItem('keyWord', JSON.stringify(params));
  }

  return (
    <>
      {
        selectList.length && selectList.map((ele, index) => {
          const { title = {}, value = [] } = ele
          const { name = '' } = title
          return (
            <div className='tagName' key={index}>
              <div className='tagState'>{name}</div>
              <Select defaultValue={queryInfo[title.value] || null} style={{ width: 200 }} onChange={(value) => changeTab(value, title.value)}>
                {
                  value.length && value.map((item, index) => {
                    const { value, name = '' } = item
                    return <Option key={index} value={value}>{name}</Option>
                  })
                }
              </Select>
            </div>
          )
        })
      }
    </>
  )
}


export default Selects
