import Selects from '../select/select';
import HeadTab from '../headTab/headTab';
import './head.less';


export default function Heads(props) {
  const { searchInputval, setSearchInputval,
    queryInfo, setQueryInfo, setTabPosition, tabPosition, dataList, setDataList } = props
  return (

    <div className='headTag'>
      <Selects
        {...{
          setDataList, queryInfo, setQueryInfo, setSearchInputval, setTabPosition
        }}
      />
      <div className='headTab'>
        <HeadTab {...{
          setQueryInfo, searchInputval, tabPosition, setSearchInputval, setTabPosition, dataList, setDataList
        }} />
      </div>
    </div>

  )
}
