import React from 'react';
import 'antd/dist/antd.css';
import './card.less';
import { Card } from 'antd';
import { Link } from 'react-router-dom';

const { Meta } = Card;

export default function Cards(props) {
  const { dataList = [], item = {}, index = 0, } = props



  return (
    <>
      <Link to={`/Details?id=${item['id']}`}>
        <Card
          hoverable
          style={{ margin: 10, width: 160 }}
          cover={<img alt="example" src={item['game_icon_url']} className='cardImg' />}
        >
          <Meta title={item['game_name']} />
        </Card>
      </Link>
    </>
  )
}
